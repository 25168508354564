import request from "@/utils/request";
import userApi from '@/const/apiUrl/user'
import method from "@/const/request/requestMethod";

// 用户列表
export function userList(searchData) {
    return request({
        url: userApi.userList,
        method: method.GET,
        data: searchData
    })
}

//成员开启[停用]用户
export function userStatus(DataId) {
    return request({
        url: userApi.userStatus,
        method: method.GET,
        data: DataId
    })
}

// 新建用户
export function userAdd(params) {
    return request({
        url: userApi.userAdd,
        method: method.POST,
        data: params
    })
}

// 删除用户
export function userDelete(params) {
    return request({
        url: userApi.userDelete,
        method: method.GET,
        data: params
    })
}

//解冻用户
export function userFreeze(params) {
    return request({
        url: userApi.userFreeze,
        method: method.GET,
        data: params
    })
}

//用户详情
export function userDetail(params) {
    return request({
        url: userApi.userDetail,
        method: method.GET,
        data: params
    })
}

//编辑用户
export function userEdit(params) {
    return request({
        url: userApi.userEdit,
        method: method.POST,
        data: params
    })
}

// 上级(选项)列表
export function userSuperiorAll(params) {
    return request({
        url: userApi.userSuperiorAll,
        method: method.GET,
        data: params
    })
}
