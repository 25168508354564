const departmentApi = {
    //部门列表
    departmentList: '/department/list',
    //新建部门
    departmentAdd: '/department/add',
    //根据ID获取部门详情
    departmentDetail: '/department/getById',
    //编辑部门
    departmentEdit: '/department/edit',
    //删除部门
    departmentDelete: '/department/delete'
}


export default departmentApi