import request from "@/utils/request";
import departmentApi from '@/const/apiUrl/department'
import method from "@/const/request/requestMethod";

//部门列表查询
export function departmentList(searchData) {
    return request({
        url: departmentApi.departmentList,
        method: method.GET,
        data: searchData
    })
}
//新建部门
export function departmentAdd(addData) {
    return request({
        url: departmentApi.departmentAdd,
        method: method.POST,
        data: addData
    })
}
//部门详情
export function departmentDetail(dataId) {
    return request({
        url: departmentApi.departmentDetail,
        method: method.GET,
        data: dataId
    })
}
//编辑部门
export function departmentEdit(editData) {
    return request({
        url: departmentApi.departmentEdit,
        method: method.POST,
        data: editData
    })
}
//删除部门
export function departmentDelete(deleId) {
    return request({
        url: departmentApi.departmentDelete,
        method: method.GET,
        data: deleId
    })
}
