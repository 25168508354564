/**
 * 请求接口地址常量
 */

const userApi = {
    //用户列表
    userList: '/user/list',
    //成员开启[停用]用户
    userStatus: '/user/changeStatus',
    // 新建用户
    userAdd: '/user/add',
    //删除用户
    userDelete: '/user/delete',
    //解冻用户
    userFreeze: '/user/unfreeze',
    //用户详情
    userDetail: '/user/getById',
    //编辑用户
    userEdit: '/user/edit',
    //上级(选项)列表
    userSuperiorAll: '/user/superiorAll'

}


export default userApi