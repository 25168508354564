import request from "@/utils/request";
import roleApi from '@/const/apiUrl/role'
import method from "@/const/request/requestMethod";

//角色列表查询
export function roleList(searchData) {
    return request({
        url: roleApi.roleList,
        method: method.GET,
        data: searchData
    })
}

// 开启[停用]角色
export function rolestatus(params) {
    return request({
        url: roleApi.rolestatus,
        method: method.GET,
        data: params
    })
}

//删除角色
export function roleDelete(params) {
    return request({
        url: roleApi.roleDelete,
        method: method.GET,
        data: params
    })
}

//新建角色
export function roleAdd(params) {
    return request({
        url: roleApi.roleAdd,
        method: method.POST,
        data: params
    })
}
//获取角色详情
export function roleDetail(params) {
    return request({
        url: roleApi.roleDetail,
        method: method.GET,
        data: params
    })
}
//编辑角色
export function roleEdit(params) {
    return request({
        url: roleApi.roleEdit,
        method: method.POST,
        data: params
    })
}
