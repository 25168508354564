/**
 * 请求接口地址常量
 */

const roleApi = {
    // 角色列表
    roleList: '/role/list',
    // 开启[停用]角色
    rolestatus: '/role/changeStatus',
    //删除角色
    roleDelete: '/role/delete',
    //新建角色
    roleAdd: '/role/add',
    //角色详情
    roleDetail: '/role/getById',
    //角色编辑
    roleEdit: '/role/edit',
    //权限资源树
    permissions: '/permission/sysPermsAll'
}


export default roleApi